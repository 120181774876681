import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
const LandingPage = lazy(() => import("./views/landing_page"));
const BookingPage = lazy(() => import("./views/booking_page"));

const HttpError = lazy(() => import("./views/http_error"));

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <LandingPage />,
      errorElement: <HttpError />,
    },
    {
      path: "/schedule",
      element: <BookingPage />,
    },
  ],
  {
    basename: "/",
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback={<></>}>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);
